
import { defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { MenuComponent } from "@/assets/ts/components";
import { ApiBase, ApiApp } from "@/core/api";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { MBTaggings } from "magicbean-saas-common";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import { setModuleBCN } from "@/core/directive/function/common";
import _ from "lodash";

interface TaggingValue {
  short_key: string;
  items: Array<TaggingValueItem>;
}

interface TaggingValueItem {
  short_key: string;
  value: string | Array<string>;
}

export default defineComponent({
  name: "mobile-app-information",
  components: { MBTaggings },
  setup() {
    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const pimTaggingsRef = ref<null | HTMLFormElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const deleteButton = ref<HTMLElement | null>(null);
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();
    const short_key = "merchant_channel_mobile_app_description";

    const taggingSource = ref([]);
    const taggingValues = ref([]);
    const getTaggingDataSource = async () => {
      const { data } = await ApiBase.getTaggingGroupData({
        short_key: short_key,
      });
      if (data.code == 0) {
        taggingSource.value =
          data.data.merchant_channel_mobile_app_description.items;
      }
    };

    const formData = ref({
      id: route.params.id,
      status: 10,
      name: "",
      short_key: "",
      email: "",
      website: "",
      telephone: "",
      telephone2: "",
      fax: "",
      merchant_id: "",
      address: "",
      zip_code: "",
      city: "",
      country_iso_2: "",
      app_type: "",
      overall_notes: "",
      tagging_data: [] as any[],
      inter_contact: "",
      __show: {
        created_uid: "",
        updated_uid: "",
      },
    });

    const parentCompanys = ref([]);
    const rules = ref({
      name: [
        {
          required: true,
          message: "Mobile Applications Name is required",
          trigger: "change",
        },
      ],
      short_key: [
        {
          required: true,
          message: "Company Short-Key is required",
          trigger: "change",
        },
      ],
      telephone: [
        {
          required: true,
          message: "Telephone is required",
          trigger: "change",
        },
      ],
      app_type: [
        {
          required: true,
          message: "App Type is required",
          trigger: "change",
        },
      ],
      merchant_id: [
        {
          required: true,
          message: "Parent Merchant is required",
          trigger: "change",
        },
      ],
    });
    const appTypes = ref([]);
    const getAppTypeOptions = () => {
      ApiBase.getTaggingData({ short_key: "global_app_type" })
        .then(({ data }) => {
          if (data.code == 0) {
            appTypes.value = data.data.items;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const details = ref({
      name: "",
      email: "",
      address: "",
      website: "",
    });
    const getAppData = async () => {
      loading.value = true;
      const { data } = await ApiApp.getAppInfo({ id: route.params.id });
      loading.value = false;
      if (data.code == 0) {
        formData.value = data.data;
        let { name, email, address, website } = JSON.parse(
          JSON.stringify(data.data)
        );
        details.value = {
          name,
          email,
          address,
          website,
        };
        if (data.data.tagging_data.merchant_channel_mobile_app_description) {
          taggingValues.value =
            data.data.tagging_data.merchant_channel_mobile_app_description.items;
        }
        getMerchantSourceData("", formData.value.merchant_id);
      }
    };

    const deleteApp = () => {
      Swal.fire({
        text: "Are you sure you would like to deactivate your Mobile Applications?",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          if (deleteButton.value) {
            deleteButton.value.setAttribute("data-kt-indicator", "on");
          }
          loading.value = true;
          ApiApp.deleteApp({ id: route.params.id })
            .then(({ data }) => {
              loading.value = false;
              deleteButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                Swal.fire({
                  text: "Your Mobile Applications has been deactivated!",
                  icon: "success",
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  router.push({ name: "mobile-applications" });
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    };

    const submit = () => {
      let taggingData = pimTaggingsRef.value?.formData;
      let taggingArr: Array<TaggingValue> = [];
      let items: Array<TaggingValueItem> = [];
      for (let item in taggingData) {
        items.push({
          short_key: item,
          value: taggingData[item],
        });
      }
      let taggingObj: TaggingValue = {
        short_key: "merchant_channel_mobile_app_description",
        items: items,
      };
      taggingArr.push(taggingObj);

      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          formData.value.tagging_data = taggingArr;
          ApiApp.updateApp(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg();
                let { name, email, address, website } = JSON.parse(
                  JSON.stringify(formData.value)
                );
                details.value = {
                  name,
                  email,
                  address,
                  website,
                };
                console.log(data);
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const getMerchantSourceData = async (value, id?) => {
      let params = id
        ? {
            id: id,
          }
        : {
            search_value: value,
          };
      const { data } = await ApiBase.getMerchantSourceData(params);
      if (data.code == 0) {
        parentCompanys.value = data.data;
      }
    };

    const debounceMerchantSourceSearch = _.debounce(
      getMerchantSourceData,
      1000
    );

    const searchMerchantSourceItems = (query: string) => {
      debounceMerchantSourceSearch(query);
    };

    onMounted(() => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route, router);
      getAppTypeOptions();
      Promise.all([getTaggingDataSource(), getAppData()])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    });

    return {
      loading,
      formData,
      details,
      rules,
      formRef,
      submitButton,
      deleteButton,
      submit,
      t,
      parentCompanys,
      getAppData,
      appTypes,
      deleteApp,
      taggingSource,
      taggingValues,
      pimTaggingsRef,
      searchMerchantSourceItems,
    };
  },
});
